<template>
    <modal ref="modalCrearNewRaizRuta" titulo="Añadir raíz de rutas" adicional="Guardar" @adicional="add">
        <ValidationObserver ref="createNewRaizRuta">
            <div class="row m-3 f-15">
                <div class="col-12">
                    <p class="input-label-top">Nombre</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="generalNewRaizRuta.nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                    <br/>
                    <p class="input-label-top">Nombre de ruta</p>
                    <ValidationProvider v-slot="{errors}" name="Nombre de ruta" rules="required">
                        <el-input v-model="generalNewRaizRuta.name_ruta" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {  mapActions } from 'vuex'
export default {
    data(){
        return {
            generalNewRaizRuta: {
                nombre: '',
                name_ruta: ''
            },
        }
    },
    computed: {
        idMenu(){ 
            return this.$route.params.id
        }
    },
    methods: {
        ...mapActions({
            Action_create_raiz_ruta : 'configurar/permisos/menus/Action_create_raiz_ruta'
        }), 
        toggle(){
            this.$refs.createNewRaizRuta.reset()
            this.$refs.modalCrearNewRaizRuta.toggle()
        },
        async add(){
            const valid = await this.$refs.createNewRaizRuta.validate()
            if (valid) {
                this.generalNewRaizRuta.configuracion = 1;
                this.generalNewRaizRuta.estado = 1;
                this.generalNewRaizRuta.id_menu_superior = this.idMenu;
                const newRaizRuta = await this.Action_create_raiz_ruta(this.generalNewRaizRuta);
                this.$emit('newRaiz', newRaizRuta);
                this.$refs.modalCrearNewRaizRuta.toggle()
            }
            this.clear()
        },
        clear(){
            this.generalNewRaizRuta = {
                nombre: '',
                descripcion: ''
            }
        }
    }
}
</script>